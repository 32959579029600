<template>
    <section class="section">
        <h2>お支払い</h2>

        <inline-loader v-if="account_loading"></inline-loader>
        <div v-else>
            <div class="card">
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-3">ステータス</div>
                        <div class="col-21">
                            <span v-if="account.paid_status === AccountPaidStatus.PENDING" class="font-weight-bold text-warning">未払い</span>
                            <span v-else-if="account.paid_status === AccountPaidStatus.FAILED" class="font-weight-bold text-danger">決済失敗</span>
                            <span v-else class="font-weight-bold text-success">正常</span>
                        </div>
                    </div>

                    <template v-if="false === $store.getters['user/auth/canManagePayment']()">
                        <div class="alert alert-warning">お支払い情報を変更する権限がありません</div>
                    </template>
                    <template v-else-if="account.paid_status === AccountPaidStatus.PENDING">
                        <h4 class="mb-3">お支払い</h4>
                        <div class="mb-3 row">
                            <div class="col-6">
                                <label class="form-label">クーポン</label>
                                <input type="text" class="form-control" v-model="coupon_code" placeholder="クーポンコード">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-6">
                                <label class="form-label">プラン</label>
                                <form-select
                                    v-model="plan"
                                    :options="plans"
                                    :required="true"
                                />
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-12">
                                <label class="form-label">クレジットカード</label>
                                <label id="payjpForm" class="payjs-outer form-control"></label>
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary" @click="checkout()">登録</button>

                    </template>
                    <template v-else-if="account.paid_status === AccountPaidStatus.FREE">
                        <div class="alert alert-secondary">このアカウントは決済が不要です。</div>
                    </template>
                    <template v-else>
                        <h4 class="mb-3">カードを変更</h4>
                        <div class="mb-3 row">
                            <div class="col-12">
                                <label class="form-label">クレジットカード</label>
                                <label id="payjpForm" class="payjs-outer form-control"></label>
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary" @click="changeCard()">変更</button>

                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import InlineLoader from '@/shared/components/tool/InlineLoader';
import AccountPaidStatus from '@/shared/models/enums/account-paid-status'
import FormSelect from '@/shared/components/form/FormSelect.vue';

export default {
    name: 'PagePayment',
    components: {
        InlineLoader,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage'
    ],
    data() {
        return {
            account_loading: true,
            account: null,
            coupon_code: null,
            plan: 1,
            plans: [
                {key: 1, label: '毎月払い'},
                {key: 3, label: '年払い'},
            ],

            payjp: null,
            cardElement: null,
            AccountPaidStatus,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.account_loading = true;
            this.$http.get('/me/account')
            .then((response) => {
                this.account = response.data;
                //auth情報も最新化
                this.$store.commit('user/auth/setAccount', this.account);
                //PayJP
                setTimeout(() => {
                    this.payjp = window.Payjp(process.env.VUE_APP_PAYJP_PUBLIC_KEY);
                    var style = {
                        base: {
                            color: '#198fcc',
                            '::placeholder': {
                                fontStyle: 'italic',
                                color: 'green',
                            }
                        },
                        invalid: {
                            color: 'red',
                        }
                    };
                    this.cardElement = this.payjp.elements().create('card', {style: style});
                    this.cardElement.mount('#payjpForm');
                }, 500);
            })
            .finally(() => {
                this.account_loading = false;
            });
        },
        checkout() {
            this.startScreenLoading();
            this.payjp.createToken(this.cardElement).then((response) => {
                if (response.error) {
                    this.showErrorMessage(response.error.message);
                    this.endScreenLoading();
                } else {
                    this.$http.post('/payment/checkout', {
                        payjp_card_token: response.id,
                        subscription_plan: this.plan,
                        coupon_code: this.coupon_code,
                    })
                    .then((response) => {
                        this.account = response.data;
                        //auth情報も最新化
                        this.$store.commit('user/auth/setAccount', this.account);
                        this.showMessage('登録完了しました');
                    })
                    .finally(() => {
                        this.endScreenLoading();
                    });
                }
            })
        },
        changeCard() {
            this.startScreenLoading();
            this.payjp.createToken(this.cardElement).then((response) => {
                if (response.error) {
                    this.showErrorMessage(response.error.message);
                    this.endScreenLoading();
                } else {
                    this.$http.post('/payment/change', {
                        payjp_card_token: response.id,
                    })
                    .then((response) => {
                        this.account = response.data;
                        //auth情報も最新化
                        this.$store.commit('user/auth/setAccount', this.account);
                        this.showMessage('変更完了しました');
                    })
                    .finally(() => {
                        this.endScreenLoading();
                    });
                }
            })
        }

    },
}
</script>

<style scoped>

</style>
